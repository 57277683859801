import React from 'react';
import ReactDOM from 'react-dom/client';
import BackgroundPattern from "./components/BackgroundPattern";
import Home from './components/Home';
import AboutMe from './components/AboutMe';
import Projects from './components/Projects';
import Contact from './components/Contact';
import BottomBar from "./components/BottomBar";
import './css/index.css';


// @ts-ignore
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <div className="app">
            <BackgroundPattern/>
            <Home/>
            <AboutMe/>
            <Projects/>
            <Contact/>
            <BottomBar/>
        </div>
    </React.StrictMode>
);

