import React from 'react';
import '../css/contact.css';

function Contact() {
    const openEmailClient = () => {
        window.location.href = 'mailto:me@ollieee.xyz';
    };

    return (
        <section className="contact">
            <h2>Contact Me</h2>
            <div className="contact-form">
                <button onClick={openEmailClient}>Email Me</button>
            </div>
            <h3>Where am I?</h3>
            <div className="map">
                <iframe
                    title="Birmingham Map"
                    width="50%"
                    height="700"
                    src="https://www.openstreetmap.org/export/embed.html?bbox=-3.0473327636718754%2C52.10481822023324%2C-0.7539367675781251%2C52.8492298820527&amp;layer=mapnik"
                ></iframe>
            </div>
        </section>
    );
}

export default Contact;
