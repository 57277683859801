import React from 'react';
import '../css/bottom-bar.css';

function BottomBar() {
    return (
        <div className="bottom-bar">
            <p>&copy; {new Date().getFullYear()} Ollie. All rights reserved.</p>
        </div>
    );
}

export default BottomBar;
